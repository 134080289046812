export const intervalRound = (val, cb) => {
    let v = val
    let i = setInterval(() => {
        if(v > 0) {
            cb(v - 0.1)
            v = v - 0.1
        } else {
            cb(0)
            clearInterval(i)
        }
    }, 100)
}
