import React, {useState} from 'react';
import classNames from "classnames";
import {numberWithCommasNull} from "../../helpers/numberWithCommas";
import io from "socket.io-client";

const Lobby = (props) => {

    const {placeBet} = props

    const [betAmount, setBetAmount] = useState(0)

    const amounts = [
        {
            amount: 10000,
            title: 'Rookie:'
        }, {
            amount: 100000,
            title: 'Veteran:'
        }, {
            amount: 1000000,
            title: 'Combat Master:'
        },
    ]

    return (
        <div className={'lobby_game'}>
            <img className={'lobby_game_logo'} src={'/images/pvp_logo.png'} alt={'mpeppe_pvp_game'}/>
            <div className="points_amount">
                <h2 className={'points_amount_title'}>Make your Bet:</h2>
                <ul className={'points_amount_list'}>
                    {amounts.map(amount => {
                        return <li

                            key={amount.title}
                            onClick={() => {
                                setBetAmount(amount.amount)
                            }}
                            className={classNames({
                                'points_amount_item': true,
                                'active': betAmount === amount.amount
                            })}
                        ><span
                            className={'points_amount_item_title'}
                        >{amount.title}</span><span
                            className={'points_amount_item_amount'}>{numberWithCommasNull(amount.amount)}<span
                            className={'mp'}>MP</span></span></li>
                    })}
                </ul>
            </div>
            <button
                disabled={!betAmount}
                className={'invite_button_button'}
                onClick={() => placeBet(betAmount)}
            >Find Opponent
            </button>
        </div>
    );
};

export default Lobby;