import React, {useState} from 'react';
import FightingGame from "./game";
import './anim.css'
import classNames from "classnames";
import WithFrame from "../HOC/WithFrame";
import HowToPlay from "../PTE/Clash/howToPlay";

const slides = [
    {
        img: 'step_1',
        content: <p>Select your bet size and click 'Find Opponent'</p>
    },
    {
        img: 'step_2',
        content: <><p>Wait for an opponent with the same bet size.</p> <p>After the opponent connects, the game will
            begin.</p></>
    },
    {
        img: 'step_3',
        content: <><p>Choose directions of <span style={{color: '#e94560'}}>attack</span> and <span
            style={{color: 'rgba(255, 255, 255, 0.50)'}}>defense</span>. Click 'Ready'.</p> <p>You have 20 seconds to do
            this. The
            timer at the top is counting down.</p> <p>If you don't have time to make a choice, the system will do it
            randomly.</p></>
    },
    {
        img: 'step_4',
        content: <p>Next the battle will begin. If you choose a direction of attack different from your opponent’s
            defense, you will receive <span style={{color: '#2EBD85', fontWeight: 'bold'}}>+1</span> point.
        </p>
    },
    {
        img: 'step_5',
        content: <><p>The game continues until <span style={{color: '#2EBD85', fontWeight: 'bold', fontSize: '16px'}}>3</span> points. If the opponents score 3 points at the same time, the game will continue until someone scores one more point.</p><p>The winner takes the opponent's bet as winnings.</p></>
    }
];

const PvpFight = () => {

    const [howToVisible, setHowToVisible] = useState(false);

    return (
        <WithFrame
            className={classNames({
                'pvp_game': true,
            })}
            header={
                <div className='penalty_shot_header'>
                    <span>Fight Arena</span>
                    <span
                        onClick={() => setHowToVisible(true)}
                    >
                        <img style={{width: '20px'}} src={'/images/clash/how.svg'} alt={'how to play'}/>
                    </span>
                </div>
            }
        >
            <FightingGame/>
            {howToVisible && <HowToPlay
                img_path={'pvp'}
                slides={slides}
                setHowToVisible={setHowToVisible}
            />}
        </WithFrame>
    );
};

export default PvpFight;