import React from 'react';
import {NavLink} from "react-router-dom";
import {nav_items} from "../../../nav_items";

const Nav = () => {


    const NavItem = ({item}) => {
        return <li className='nav_item'>
            <NavLink to={item.path}>{item.title}</NavLink>
        </li>
    }

    return (
        <nav className='nav'>
            <ul className='nav_wrapper'>
                {nav_items.map(item => {
                    return <NavItem
                        key={item.id}
                        item={item}
                    />
                })}
                <NavLink to={'/pvp-fight'} className={'aidrop'}>
                   <span style={{display: 'flex', padding: '16px', boxSizing: 'border-box'}}><img  style={{width: '100%'}} src={'/images/pvp/swords.png'} alt={'blades'}/></span>
                </NavLink>
                {/*<NavLink to={'/airdrop'} className={'aidrop'}>*/}
                {/*   <span>Airdrop</span>*/}
                {/*</NavLink>*/}
            </ul>
        </nav>
    );
};

export default Nav;