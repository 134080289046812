import React, {useEffect, useState} from 'react';
import {SpriteAnimator} from "react-sprite-animator";
import './anim.css'
import classNames from "classnames";

const FightArena = (props) => {

    const {p_1_action, p_2_action, isWin, player, play, playerNumber} = props;

    const [playAnim, setPlayAnim] = useState(false)

    useEffect(() => {
        if(play) {
            setTimeout(() => {
                setPlayAnim(true)
            }, 50)
        } else {
            setPlayAnim(false)
        }
    }, [play]);

    return (
        <div
            className={classNames({
                'fight_arena_animation': true,
                'rotate': playerNumber > 0
            })}
        >

            <div
                className={classNames({
                    "anim_wrapper": true,
                    "anim_wrapper_l": true,
                    [p_1_action]: true,
                    "play": play
                })}
            >
                <SpriteAnimator
                    sprite={`/images/pvp/sprites/${p_1_action}.png`}
                    width={96}
                    height={96}
                    fps={4}
                    shouldAnimate={playAnim}
                    stopLastFrame={true}
                    frame={!playAnim ? 1 : 0}
                    onEnd={() => {

                    }}
                />
            </div>
            <div
                className={classNames({
                    "anim_wrapper": true,
                    "anim_wrapper_r": true,
                    [p_2_action]: true,
                    "play": play
                })}

            >
                <SpriteAnimator
                    sprite={`/images/pvp/sprites/${p_2_action}.png`}
                    width={96}
                    height={96}
                    fps={4}
                    shouldAnimate={playAnim}
                    stopLastFrame={true}
                    frame={!playAnim ? 1 : 0}
                    onEnd={() => {

                    }}
                />
            </div>
        </div>
    );
};

export default FightArena;