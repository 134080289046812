import React, {useState, useEffect, useRef} from 'react';
import io from 'socket.io-client';
import WaitingOpponent from "./waitingOpponent";
import Lobby from "./lobby";
import classNames from "classnames";
import FightArena from "./fightArena";
import {intervalRound} from "./interval";
import {makeConfetti} from "../../helpers/confetti";

let socket = {}

const directions = ['Head', 'Body', 'Legs'];

const ConnectionStatus = {
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    NOT_RESPONDING: 'NOT_RESPONDING',
    DISCONNECTED: 'DISCONNECTED'
};

const FightingGame = () => {
    const [gameState, setGameState] = useState({
        player: {attack: '', defense: '', score: 0, ready: false},
        opponent: {attack: '', defense: '', score: 0, ready: false},
        gameOver: false,
        winner: null,
        timeLeft: 20,
        showResults: false,
        roundEnded: false,
        bet: 0,
        currentRound: 1
    });

    const [roundTime, setRoundTime] = useState(10)

    const [playerNumber, setPlayerNumber] = useState(null);
    const [waitingForOpponent, setWaitingForOpponent] = useState(true);
    const [message, setMessage] = useState('');
    const [selectedBet, setSelectedBet] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState(ConnectionStatus.CONNECTING);
    const [showNewRoundMessage, setShowNewRoundMessage] = useState(false);
    const [currentAnimation, setCurrentAnimation] = useState('');
    const [isPlay1, setIsPlay1] = useState(false);
    const [isPlay2, setIsPlay2] = useState(false);

    useEffect(() => {
        socket = io('wss://tapp-stg.mpeppe.io/v1/ws/test', {
        // socket = io('http://localhost:3002', {
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });
    }, [])

    useEffect(() => {
        socket.on('connect', () => {
            setConnectionStatus(ConnectionStatus.CONNECTED);
            setMessage('');
        });

        socket.on('connect_error', (error) => {
            setConnectionStatus(ConnectionStatus.NOT_RESPONDING);
            setMessage('Ошибка подключения к серверу. Пожалуйста, проверьте ваше интернет-соединение.');
        });

        socket.on('disconnect', (reason) => {
            setConnectionStatus(ConnectionStatus.DISCONNECTED);
            setMessage('Соединение с сервером потеряно. Пытаемся переподключиться...');
        });

        socket.on('playerNumber', (number) => {
            setPlayerNumber(number);
            setWaitingForOpponent(false);
        });

        socket.on('waitingForOpponent', (bet) => {
            setMessage(`Ожидание соперника со ставкой ${bet}...`);
        });

        socket.on('gameStart', ({roomId, bet}) => {
            setGameState(prevState => ({...prevState, bet}));
            setWaitingForOpponent(false);
            setMessage('');
        });

        socket.on('gameState', (state) => {
            setGameState(prevState => ({
                ...prevState,
                ...state,
            }));
        });

        socket.on('timeUpdate', (timeLeft) => {
            setGameState(prevState => ({...prevState, timeLeft}));
        });

        socket.on('roundResults', (results) => {
            setGameState(prevState => ({
                ...prevState,
                player: results.player,
                opponent: results.opponent,
                gameOver: results.gameOver,
                winner: results.winner,
                timeLeft: results.timeLeft,
                bet: results.bet,
                currentRound: results.currentRound,
                showResults: true,
                roundEnded: true
            }));
            setShowNewRoundMessage(true);
            setTimeout(() => {
                setShowNewRoundMessage(false);
            }, 10000);  // 10 секунд до начала нового раунда
        });

        socket.on('playerReadyUpdate', ({player, ready}) => {
            setGameState(prevState => ({
                ...prevState,
                [player === playerNumber ? 'player' : 'opponent']: {
                    ...prevState[player === playerNumber ? 'player' : 'opponent'],
                    ready
                }
            }));
        });

        socket.on('roundStart', ({currentRound, timeLeft, gameState}) => {
            setGameState(prevState => ({
                ...prevState,
                player: gameState.player || prevState.player,
                opponent: gameState.opponent || prevState.opponent,
                currentRound,
                timeLeft,
                showResults: false,
                roundEnded: false
            }));
        });

        socket.on('opponentLeft', ({message, winner, gameState}) => {
            setMessage(message);
            setGameState(prevState => ({
                ...prevState,
                ...gameState,
                gameOver: true,
                winner: winner === playerNumber ? 'player' : 'opponent'
            }));
        });

        socket.on('resetForNewGame', () => {
            setGameState({
                player: {attack: '', defense: '', score: 0, ready: false},
                opponent: {attack: '', defense: '', score: 0, ready: false},
                gameOver: false,
                winner: null,
                timeLeft: 20,
                showResults: false,
                roundEnded: false,
                bet: 0,
                currentRound: 1
            });
            setPlayerNumber(null);
            setWaitingForOpponent(true);
            setSelectedBet(null);
            setMessage('');
        });

        return () => {
            socket.off('connect');
            socket.off('connect_error');
            socket.off('disconnect');
            socket.off('playerNumber');
            socket.off('waitingForOpponent');
            socket.off('gameStart');
            socket.off('gameState');
            socket.off('timeUpdate');
            socket.off('roundResults');
            socket.off('playerReadyUpdate');
            socket.off('roundStart');
            socket.off('opponentLeft');
            socket.off('resetForNewGame');
        };
    }, [playerNumber]);

    const handleAction = (action, direction) => {
        setGameState(prevState => ({
            ...prevState,
            player: {
                ...prevState.player,
                [action]: direction
            }
        }));
        socket.emit('action', {player: playerNumber, action, direction});
    };

    const setReady = () => {
        socket.emit('playerReady', playerNumber);
    };

    const requestNewGame = () => {
        socket.emit('requestNewGame');
    };

    const placeBet = (bet) => {
        setSelectedBet(bet);
        socket.emit('placeBet', bet, "TG_ID-1234");
    };

    const {player, opponent, gameOver, winner, timeLeft, showResults, roundEnded, bet, currentRound} = gameState;

    const isPlayerReady = player?.attack && player?.defense;

    const getConnectionStatusMessage = () => {
        switch (connectionStatus) {
            case ConnectionStatus.CONNECTING:
                return 'Connecting to the server...';
            case ConnectionStatus.CONNECTED:
                return 'Connected to server';
            case ConnectionStatus.NOT_RESPONDING:
                return 'Server is not responding';
            case ConnectionStatus.DISCONNECTED:
                return 'The connection to the server is lost';
            default:
                return '';
        }
    };

    const [scores, setScores] = useState({
        player_: 0,
        opponent_: 0
    })

    const [showGameOver, setShowGameOver] = useState(false)

    useEffect(() => {
        if (showResults && gameOver) {
            setTimeout(() => {
                setScores({player_: player.score, opponent_: opponent.score})
            }, 4000)
        }
        if (showResults && !gameOver) {

            intervalRound(10, setRoundTime)

            setCurrentAnimation('p_1')
            setIsPlay1(true)
            setIsPlay2(false)
            setTimeout(() => {
                setScores({player_: player.score, opponent_: opponent.score})
                setIsPlay1(false)
                setIsPlay2(true)
                setCurrentAnimation('p_2')
            }, 5000)
            setTimeout(() => {
                setScores({player_: player.score, opponent_: opponent.score})
                setIsPlay1(false)
                setIsPlay2(false)
            }, 9000)
        }
    }, [showResults])

    useEffect(() => {
        if (gameOver) {

            winner === 'player' && playerNumber === 0 && setCurrentAnimation('p_2_death')
            winner === 'opponent' && playerNumber === 1 && setCurrentAnimation('p_2_death')

            winner === 'player' && playerNumber === 1 && setCurrentAnimation('p_1_death')
            winner === 'opponent' && playerNumber === 0 && setCurrentAnimation('p_1_death')

            setTimeout(() => {
                setShowGameOver(true)
                winner === 'player' && makeConfetti()
            }, 4000)

        }
    }, [gameOver])

    if (connectionStatus !== ConnectionStatus.CONNECTED) {
        return (
            <div className="info_components">
                <WaitingOpponent/>
                <div className="game-content">
                    <p className="connection-status">{getConnectionStatusMessage()}</p>
                    <p className="error-message">{message}</p>
                </div>
            </div>
        );
    }

    if (selectedBet === null) {
        return (
            <Lobby
                placeBet={placeBet}
            />
        );
    }

    if (waitingForOpponent) {
        return (
            <div className="info_components">
                <WaitingOpponent/>
                <p>{message || 'Waiting for your opponent to connect...'}</p>
            </div>
        );
    }

    return (
        <div className="fighting-game">
            <div className="game-content">
                <div className={'arena_bg'}
                     style={{backgroundImage: "url(/images/pvp/arena_bg.png)"}}
                >
                    <>
                        <div className="top_info">
                            <div className={'top_board'}>
                                <img src={'/images/pvp/score.png'} alt={'vs'}/>
                                <div className="score">
                                    <div className="score_round">
                                        Round {currentRound}
                                    </div>

                                    <div className="score_points">
                                    <span
                                        className={classNames({
                                            'point': true,
                                            'less': player.score < opponent.score,
                                            'eq': player.score === opponent.score,
                                            'more': player.score > opponent.score
                                        })}
                                    >{scores.player_}</span>
                                        <div className={'score_players'}>
                                            <span className='player'>You</span>
                                            <span className='player'>Opponent</span>
                                        </div>

                                        <span
                                            className={classNames({
                                                'point': true,
                                                'less': player.score > opponent.score,
                                                'eq': player.score === opponent.score,
                                                'more': player.score < opponent.score
                                            })}
                                        >{scores.opponent_}</span>
                                    </div>

                                    <div
                                        className={classNames({
                                            "score_timer": true,
                                            "les_10": timeLeft < 10 && timeLeft >= 5,
                                            "les_5": timeLeft < 5,
                                        })}
                                    >0:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}</div>
                                </div>
                            </div>

                            {message && <div className="message">{message}</div>}
                        </div>
                    </>
                    <div className="fight_arena">
                        {(() => {
                            switch (currentAnimation) {
                                case 'p_1':
                                    return <FightArena
                                        playerNumber={playerNumber}
                                        play={isPlay1}
                                        p_1_action={'p_1_attack'}
                                        p_2_action={'p_2_def'}
                                    />
                                case 'p_2':
                                    return <FightArena
                                        playerNumber={playerNumber}
                                        play={isPlay2}
                                        p_1_action={'p_1_def'}
                                        p_2_action={'p_2_attack'}
                                    />
                                case 'p_1_death':
                                    return <FightArena
                                        playerNumber={playerNumber}
                                        play={true}
                                        p_1_action={'p_1_death'}
                                        p_2_action={'p_2_attack'}
                                    />
                                case 'p_2_death':
                                    return <FightArena
                                        playerNumber={playerNumber}
                                        play={true}
                                        p_1_action={'p_1_attack'}
                                        p_2_action={'p_2_death'}
                                    />
                                default:
                                    return <FightArena
                                        playerNumber={playerNumber}
                                        play={isPlay1 && !gameOver}
                                        p_1_action={'p_1_attack'}
                                        p_2_action={'p_2_def'}
                                    />
                            }
                        })()}
                    </div>
                </div>

                <div className="game-content">

                    {gameOver ? (
                        <>

                        {!showGameOver && <div className="round-results_content">
                                {currentAnimation === 'p_2_death' && playerNumber === 0 ?
                                    <div className={'results_view'}>
                                        <div className={classNames({
                                            "results_view_point": true,
                                            "anim": player.attack !== opponent.defense
                                        })}>
                                            <span>{player.attack !== opponent.defense ? '+1' : ''}</span>
                                        </div>
                                        <h2>Your attack</h2>
                                        <p className={'results_view_attack'}>
                                            You attack to the
                                            <span
                                                className={classNames({
                                                    "dir": true,
                                                    "up": player.attack !== opponent.defense,
                                                    "down": player.attack === opponent.defense
                                                })}
                                            >
                                            {player.attack}
                                        </span>
                                        </p>
                                        <p className={'results_view_defense'}>
                                            The opponent protects his
                                            <span
                                                className={classNames({
                                                    "dir": true,
                                                    "up": opponent.attack !== player.defense,
                                                    "down": opponent.attack === player.defense
                                                })}
                                            >
                                            {opponent.defense}
                                        </span>
                                        </p>
                                    </div> : currentAnimation === 'p_2_death' && playerNumber === 1 ?
                                        <div className={'results_view'}>
                                            <div className={classNames({
                                                "results_view_point": true,
                                                "anim": opponent.attack !== player.defense
                                            })}>
                                                <span>{opponent.attack !== player.defense ? '+1' : ''}</span>
                                            </div>
                                            <h2>Enemy attack:</h2>
                                            <p className={'results_view_attack'}>The opponent attacks the
                                                <span
                                                    className={classNames({
                                                        "dir": true,
                                                        "up": opponent.attack !== player.defense,
                                                        "down": opponent.attack === player.defense
                                                    })}
                                                >{opponent.attack}</span>
                                            </p>
                                            <p className={'results_view_defense'}>You protect your
                                                <span
                                                    className={classNames({
                                                        "dir": true,
                                                        "up": opponent.attack === player.defense,
                                                        "down": opponent.attack !== player.defense
                                                    })}
                                                >{player.defense}</span>
                                            </p>
                                        </div> : currentAnimation === 'p_1_death' && playerNumber === 0 ?
                                            <div className={'results_view'}>
                                                <div className={classNames({
                                                    "results_view_point": true,
                                                    "anim": opponent.attack !== player.defense
                                                })}>
                                                    <span>{opponent.attack !== player.defense ? '+1' : ''}</span>
                                                </div>
                                                <h2>Enemy attack:</h2>
                                                <p className={'results_view_attack'}>The opponent attacks the
                                                    <span
                                                        className={classNames({
                                                            "dir": true,
                                                            "up": opponent.attack !== player.defense,
                                                            "down": opponent.attack === player.defense
                                                        })}
                                                    >{opponent.attack}</span>
                                                </p>
                                                <p className={'results_view_defense'}>You protect your
                                                    <span
                                                        className={classNames({
                                                            "dir": true,
                                                            "up": opponent.attack === player.defense,
                                                            "down": opponent.attack !== player.defense
                                                        })}
                                                    >{player.defense}</span>
                                                </p>
                                            </div> : <div className={'results_view'}>
                                                <div className={classNames({
                                                    "results_view_point": true,
                                                    "anim": player.attack !== opponent.defense
                                                })}>
                                                    <span>{player.attack !== opponent.defense ? '+1' : ''}</span>
                                                </div>
                                                <h2>Your attack</h2>
                                                <p className={'results_view_attack'}>
                                                    You attack to the
                                                    <span
                                                        className={classNames({
                                                            "dir": true,
                                                            "up": player.attack !== opponent.defense,
                                                            "down": player.attack === opponent.defense
                                                        })}
                                                    >
                                            {player.attack}
                                        </span>
                                                </p>
                                                <p className={'results_view_defense'}>
                                                    The opponent protects his
                                                    <span
                                                        className={classNames({
                                                            "dir": true,
                                                            "up": opponent.attack !== player.defense,
                                                            "down": opponent.attack === player.defense
                                                        })}
                                                    >
                                            {opponent.defense}
                                        </span>
                                                </p>
                                            </div>
                                }
                            </div>}

                            {showGameOver && <div className="round-results_content">
                                <div className="results_view">
                                    <h2>Game Over!</h2>
                                    <p className={'results_text'}>
                                        {winner === 'player'
                                            ? <span>You have <span style={{color: '#2EBD85'}}>WON!</span></span>
                                            : winner === 'opponent'
                                                ? <span>You have <span style={{color: '#e94560'}}>LOST</span>.</span>
                                                : ""
                                        }
                                    </p>
                                    <button onClick={() => {
                                        requestNewGame()
                                        setCurrentAnimation('')
                                        setScores({
                                            opponent_: 0,
                                            player_: 0
                                        })
                                    }} className="invite_button_button">Start new Game
                                    </button>
                                </div>
                            </div>}
                        </>

                    ) : showResults ? (

                        <div className="round-results_content">

                            <div className="timer">
                                <span style={{width: `${(roundTime * 100) / 10}%`}}></span>
                            </div>

                            {currentAnimation === 'p_1' ?
                                <div className={'results_view'}>
                                    <div className={classNames({
                                        "results_view_point": true,
                                        "anim": player.attack !== opponent.defense
                                    })}>
                                        <span>{player.attack !== opponent.defense ? '+1' : ''}</span>
                                    </div>
                                    <h2>Your attack</h2>
                                    <p className={'results_view_attack'}>
                                        You attack to the
                                        <span
                                            className={classNames({
                                                "dir": true,
                                                "up": player.attack !== opponent.defense,
                                                "down": player.attack === opponent.defense
                                            })}
                                        >
                                            {player.attack}
                                        </span>
                                    </p>
                                    <p className={'results_view_defense'}>
                                        The opponent protects his
                                        <span
                                            className={classNames({
                                                "dir": true,
                                                "up": opponent.attack !== player.defense,
                                                "down": opponent.attack === player.defense
                                            })}
                                        >
                                            {opponent.defense}
                                        </span>
                                    </p>
                                </div> :
                                <div className={'results_view'}>
                                    <div className={classNames({
                                        "results_view_point": true,
                                        "anim": opponent.attack !== player.defense
                                    })}>
                                        <span>{opponent.attack !== player.defense ? '+1' : ''}</span>
                                    </div>
                                    <h2>Enemy attack:</h2>
                                    <p className={'results_view_attack'}>The opponent attacks the
                                        <span
                                            className={classNames({
                                                "dir": true,
                                                "up": opponent.attack !== player.defense,
                                                "down": opponent.attack === player.defense
                                            })}
                                        >{opponent.attack}</span>
                                    </p>
                                    <p className={'results_view_defense'}>You protect your
                                        <span
                                            className={classNames({
                                                "dir": true,
                                                "up": opponent.attack === player.defense,
                                                "down": opponent.attack !== player.defense
                                            })}
                                        >{player.defense}</span>
                                    </p>
                                </div>
                            }
                        </div>

                    ) : (
                        <>
                            <div className="player-turn">
                                <h2>Choose directions of Attack and Defense</h2>
                                <div className={'player-turn_titles'}>
                                    <p className={'title_attc'}>Attack:</p>
                                    <p className={'title_def'}>Defense:</p>
                                </div>
                                <div className="directions">

                                    <div className="action-group">

                                        <div className="action-group_wrapper attack">
                                            <img className={'action_img'}
                                                 src={`/images/pvp/p_${playerNumber + 1}_attack.png`} alt={'attack'}/>
                                            <div className={'action-group_buttons'}>
                                                {directions.map((dir) => (
                                                    <button
                                                        key={`attack-${dir}`}
                                                        onClick={() => handleAction('attack', dir)}
                                                        className={`btn ${player?.attack === dir ? 'selected' : ''}`}
                                                        disabled={player?.ready}
                                                    >
                                                        {dir}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="action-group">
                                        <div className="action-group_wrapper defense">
                                            <div className={'action-group_buttons'}>
                                                {directions.map((dir) => (
                                                    <button
                                                        key={`defense-${dir}`}
                                                        onClick={() => handleAction('defense', dir)}
                                                        className={`btn ${player?.defense === dir ? 'selected' : ''}`}
                                                        disabled={player?.ready}
                                                    >
                                                        {dir}
                                                    </button>
                                                ))}
                                            </div>
                                            <img className={'action_img'}
                                                 src={`/images/pvp/p_${playerNumber + 1}_def.png`}
                                                 alt={'def'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={setReady}
                                disabled={!isPlayerReady || player.ready}
                                className="invite_button_button"
                            >
                                {player.ready ? 'Waiting for the opponent...' : 'Ready'}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FightingGame;